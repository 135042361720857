import { Button } from '@asy/shadcn-ui/components/button';
import { Card, CardContent } from '@asy/shadcn-ui/components/card';
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@asy/shadcn-ui/components/carousel';
import { cn } from '@asy/shadcn-ui/lib/utils';
import { Link } from '@remix-run/react';
import Autoplay from 'embla-carousel-autoplay';
import { useCallback, useEffect, useState } from 'react';
import { HeroSection } from './page-layout/hero-section';
import { Typography } from './typography';

type SlideData = {
  image: string;
  title: string;
  description: string;
  ctaText: string;
  ctaLink: string;
};

export type HeroSliderProps = {
  slides: SlideData[];
  delayMs: number;
  className?: string;
};

export function HeroCarousel({ slides, delayMs, className }: HeroSliderProps) {
  const [api, setApi] = useState<CarouselApi>();
  const [_current, setCurrent] = useState(0);

  const onSelect = useCallback(() => {
    if (!api) return;
    setCurrent(api.selectedScrollSnap());
  }, [api]);

  useEffect(() => {
    onSelect();
    api?.on('select', onSelect);
    return () => {
      api?.off('select', onSelect);
    };
  }, [api, onSelect]);

  if (slides.length === 0) {
    return null;
  }

  return (
    <Carousel
      opts={{
        align: 'start',
        loop: true,
      }}
      setApi={setApi}
      plugins={[
        Autoplay({
          delay: delayMs,
          stopOnInteraction: true,
        }),
      ]}
      className={cn('relative w-full', className)}
    >
      <CarouselContent>
        {slides.map((slide, index) => (
          <CarouselItem key={index}>
            <Card className="overflow-hidden rounded-none border-none">
              <CardContent className="p-0">
                <HeroSection>
                  <img className="h-full w-full object-cover" src={slide.image} alt={slide.title} />
                  <div className="absolute left-0 top-0 h-full w-full bg-black/50"></div>
                  <div className="absolute inset-0 z-10 w-full p-10 text-primary-foreground lg:inset-auto lg:left-36 lg:top-40 lg:w-auto lg:max-w-lg lg:p-4">
                    <Typography variant="h2" as="h2" className="mb-4 font-sans">
                      {slide.title}
                    </Typography>
                    <Typography className="mb-4 font-sans">{slide.description}</Typography>
                    <Button size="lg" variant="outline" className="text-secondary-foreground" asChild>
                      <Link to={slide.ctaLink}>{slide.ctaText}</Link>
                    </Button>
                  </div>
                </HeroSection>
              </CardContent>
            </Card>
          </CarouselItem>
        ))}
      </CarouselContent>
      {slides.length > 1 && (
        <div>
          <CarouselPrevious className="left-2 hidden sm:left-4 sm:flex" />
          <CarouselNext className="right-2 hidden sm:right-4 sm:flex" />
        </div>
      )}
    </Carousel>
  );
}
